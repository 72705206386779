/*ADD SELF HOST FONTS */
@font-face {
  font-family: "Circular Std Medium";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/Circular-Std-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  src: url("assets/fonts/EuclidCircularA-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 300;
  src: url("assets/fonts/EuclidCircularA-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 500;
  src: url("assets/fonts/EuclidCircularA-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 600;
  src: url("assets/fonts/EuclidCircularA-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 600;
  src: url("assets/fonts/EuclidCircularA-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-style: italic;
  src: url("assets/fonts/EuclidCircularA-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  src: url("assets/fonts/EuclidCircularA-MediumItalic.woff2") format("woff2");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 62.5%;
  font-family: "Euclid Circular", sans-serif;
  scroll-behavior: smooth;
  background-color: #f8f8f8;
}

.app-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #f8f8f8;
}

/* Table pagination menu items */
.MuiTablePagination-menuItem {
  font-size: 1.2rem !important;
}

.MuiGrid-container a {
  text-decoration: none;
}

.MuiSelect-select {
  padding: 0 26px 0 10px !important;
}
/* a {
  color: #474951 !important;
} */

.kpNhUO {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem !important;
  font-size: 1.5rem !important;
  overflow: hidden !important;
}

.kpNhUO p {
  height: 100px !important;
  max-height: 200px;
  overflow: scroll;
}
